import auth from './methods/auth.js'
import common from './methods/common.js'
import testForm from './methods/testForm.js'

const api = {
  auth,
  common,
  testForm
}

export default api
