<template>
  <div class="home min-h-screen h-screen min-w-screen w-screen overflow-x-hidden">
    <div class="absolute">
      <Header />
    </div>
    <div class="h-full pt-14 bg-gray-1">
      <div class="h-full p-5 overflow-y-auto">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
    Header: () => import(/* webpackChunkName: "header" */ '@/components/layout/Header.vue')
  }
}
</script>
