import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth.js'
import common from './modules/common.js'
import testForm from './modules/testForm.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { auth, common, testForm }
})
