import axios from 'axios'
import endpoint from '../endpoint'
import getAuthHeaderJwt from '@/utils/getAuthHeaderJWT'

const common = {
  changePassword: (token, requestBody) => {
    const url = `${endpoint.auth.changePassword}`
    return axios.post(url, requestBody, { headers: getAuthHeaderJwt(token) })
  },
  forgotPassword: (requestBody) => {
    const url = `${endpoint.auth.forgotPassword}`
    return axios.post(url, requestBody)
  },
  login: (requestBody) => {
    const url = `${endpoint.auth.login}`
    return axios.post(url, requestBody)
  },
  register: (requestBody) => {
    const url = `${endpoint.auth.register}`
    return axios.post(url, requestBody)
  }
}

export default common
