import axios from 'axios'
import endpoint from '../endpoint'

const common = {
  checkServer: () => {
    const url = `${endpoint.general}`
    return axios.get(url)
  }
}

export default common
