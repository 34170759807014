import api from '@/api'

const state = {
  disable: false,
  loading: false
}

const actions = {
  checkServer: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api.common.checkServer().then(
        (response) => resolve(response?.data?.result),
        (error) => reject(error?.response?.data)
      )
    })
  },
  showLoading: ({ commit }) => {
    commit('setLoading', true)
  },
  hideLoading: ({ commit }) => {
    commit('setLoading', false)
  }
}

const mutations = {
  setLoading(state, value) {
    state.loading = value
  },
  setDisable(state, value) {
    state.disable = value
  }
}

const getters = {
  loading: (state) => {
    return state.loading
  },
  disable: (state) => {
    return state.disable
  }
}

export default { state, getters, actions, mutations }
