import api from '@/api'

const state = {
  user: {}
}

const actions = {
  changePassword: ({ _ }, { token, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.auth.changePassword(token, requestBody).then(
        (response) => {
          resolve(response?.data?.result)
        },
        (error) => reject(error?.response?.data)
      )
    })
  },
  forgotPassword: ({ _ }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.auth.forgotPassword(requestBody).then(
        (response) => resolve(response?.data?.result),
        (error) => reject(error?.response?.data)
      )
    })
  },
  login: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.auth.login(requestBody).then(
        (response) => {
          commit('setUser', response?.data?.result)
          resolve(response?.data?.result)
        },
        (error) => reject(error?.response?.data)
      )
    })
  },
  register: ({ _ }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.auth.register(requestBody).then(
        (response) => {
          resolve(response?.data?.result)
        },
        (error) => reject(error?.response?.data)
      )
    })
  },
  setCurrentUser: ({ commit }) => {
    const data = {
      id: localStorage.getItem('id'),
      name: localStorage.getItem('name'),
      email: localStorage.getItem('email'),
      token: localStorage.getItem('token')
    }
    commit('setUser', data)
  }
}

const mutations = {
  setUser: (state, value) => {
    state.user = value
  }
}

const getters = {
  isLoggedIn: (state) => {
    return state.user.id && state.user.name && state.user.email && state.user.token ? true : false
  },
  user: (state) => {
    return state.user
  }
}

export default { state, getters, actions, mutations }
